const server_configuration = {
    "localdevelopment": {
      "address": "https://what.pizza",
      "node_address": "http://localhost:3003/",
      "ports": {
        "master_server": 3003
      }
    },
    "production": {
      "address": "https://what.pizza",
      "node_address": "https://what.pizza/nodeserver/",
      "ports": {
        "master_server": 3003
      }
    }
  };
  let server_config;
  if (window.location.hostname == "what.pizza" || window.location.hostname == "evidence.report") {
    server_config = server_configuration["production"];
  } else {
    server_config = server_configuration["localdevelopment"];
  }
  export default server_config;