import React from 'react';
import {
    Segment,
  } from 'semantic-ui-react';
import './App.css';

const PageNotFoundPage = ( ) => {
 
  return (
    <div>
      <br />
    <Segment className={`containerSegment`} raised>
        <br /><h1>Page Not Found</h1><br /><br />
    </Segment>
    </div>
  );
};

  export default PageNotFoundPage;