import React from 'react';
import {
    Segment,
  } from 'semantic-ui-react';
import './App.css';

const GravityPage = ( ) => {
 
  return (
    <div style={{height: "calc(100vh - 10px)", display: "flex"}}>
      <iframe src="https://trinket.io/embed/pygame/30c572cd9b?outputOnly=true" scrolling="no" style={{overflow:"hidden"}} width="100%" height="100%" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen />
      <div style={{width: "730px", margin: "6px"}}>
        <h3 style={{marginBottom: "4px"}}>Readme</h3>
        <ol style={{paddingLeft: "14px", marginTop: "0px"}}>
            <li>How to Use</li>
                <ol type="a" style={{paddingLeft: "12px"}}>
                    <li>Click the Run button to load the Python program.</li>
                    <li style={{fontWeight: "bold"}}><b>Press the number keys at the top of the keyboard and the Q, W, E, Z, X keys to load a scenario.</b></li>
                    <li>"Size" only increases the visual size of an object so it's easier to see. It doesn't change the gravity, and it doesn't cause any collisions even if they now appear to be overlapping.</li>
                    <li>"Speed" increases the speed of the simulation. Non-linear increments.</li>
                </ol>
            <li>Keyboard Shortcut Keys</li>
                <ol type="a" style={{paddingLeft: "12px"}}>
                    <li>H toggle on/off the HUD (buttons and scale).</li>
                    <li>R resets the current scenario.</li>
                    <li>F toggles the blur effect, which may be seen if the speed is increased.</li>
                </ol>
            <li>Scenarios</li>
                <ol type="a" style={{paddingLeft: "12px"}}>
                    <li>Scenario 0 is an object falling to Earth</li>
                    <li>Scenario 1 is an object with some initial velocity to take off, but it's pulled down</li>
                    <li>Scenario 2 is the same as scenario 1, but with more velocity and it is zoomed out to show the curvature of the Earth</li>
                    <li>Scenario 3 is an object with enough initial velocity to take off and it stays in orbit</li>
                    <li>Scenario 4 is the moon orbiting the Earth</li>
                    <li>Scenario 5 shows two objeects of the same mass orbiting each other</li>
                    <li>Scenario 6 is an orbit being created with no starting velocity</li>
                    <li>Scenario 7 shows three objects orbiting each other for some time before colliding</li>
                    <li>Scenario 8 shows the inner solar system</li>
                    <li>Scenario 9 shows all the planets in the solar system orbiting the Sun</li>
                    <li>Scenario 10 (Q) shows the Sun orbiting the Super Massive Black Hole</li>
                    <li>Scenario 11 (W) shows the Earth and the Moon, plus 10 randomly placed objects moving at random velocities</li>
                    <li>Scenario 12 (E) shows the Earth and the Moon, and a counter-clockwise moon on crash course with regular moon</li>
                    <li>Scenario 13 (Z) is the same as Scenario 6, but there are 60 randomly placed objects. The objects that remain in orbit usually all go in the same direction.</li>
                    <li>Scenario 14 (X) is tracking the Earth, so that is giving an Earth centric perspective which will make the other planets and the sun appear to be orbitting the Earth.</li>
                </ol>
            <li>Final Notes</li>
                <ol type="a" style={{paddingLeft: "12px"}}>
                    <li>All collisions are perfectly inelastic.</li>
                    <li>There is something weird with how long it takes for real-world objects to orbit in some scenarios. I don't know if that is the fault of my math or if there needs to be a specific distance or speed for the orbitting objects.</li>
                </ol>
        </ol>

        <b>- Created by Khalid Shahin Spring 2014</b>
        </div>
    </div>
  );
};

  export default GravityPage;